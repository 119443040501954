@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}



body {
  font-family: var(--font-poppins)
}
 
.filter-gray {
  filter: brightness(0) saturate(100%) invert(47%) sepia(0%) saturate(217%)
    hue-rotate(32deg) brightness(98%) contrast(92%);
}

.scroll-content { /* Start with content off-screen */
  animation: scroll-left 25s linear infinite; /* Adjust duration as needed */
}

@keyframes scroll-left {
  0% {
    transform: translateX(-10%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End off-screen to the left */
  }
}


.NavlinkHover::after {
  position: absolute;
  content: "";
  background: rgb(133, 196, 65);
  bottom: -2px;
  right: 0;
  height: 2px;
  width: 0%;
  transition: 300ms ease-in-out;
}
.NavlinkHover:hover::after {
  width: 100%;
  left: 0;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .wrapper {
    @apply max-w-7xl lg:mx-auto p-5 md:px-10 xl:px-2 ;
  }
  .wrapper-dashboard {
    @apply max-w-7xl lg:mx-auto p-5 md:px-16 xl:px-10 2xl:px-2 w-full;
  }

  .linkHover {
    @apply absolute left-0 bottom-[-2px] w-0 h-[2px] bg-lime-500 transition-all duration-300 hover:w-full hover:left-0 ;
  }

 .buttonColor{
 background-color: rgb(88, 100, 219);
 }
 .cardBgabout{
 background-color: #D9D9D9;
 }
 .textColor{
 color: rgb(88, 100, 219);
 }

  .headermenu{
    background-color: #20228D ;
  }
  .bgcolorgold{
    background-color: white ;
  }
    /* TYPOGRAPHY */
 .primary-textcolor{
  color:#13131A
 }
 .secondary-textcolor{
  color:#666
 }

  .headersticky{
    @apply fixed
  }
  
  .headerabsolute{
    @apply absolute
  }

  .wrapper-weaves {
    @apply lg:mx-auto p-2 md:px-7 w-full;
  }

  .wrapper-newprice {
    @apply lg:mx-auto p-2 px-6  md:px-10 w-full;
  }
  .set-wrap {
    @apply max-w-7xl lg:mx-auto p-2  md:px-6 w-full;
  }
  
  .flex-center {
    @apply flex justify-center items-center;
  }

  .flex-between {
    @apply flex justify-between items-center;
  }


}